(function () {
  moment.locale(I18n.locale);
  d3Locale = d3.locale({
    "dateTime": "%A, %e %B %Y г. %X",
    "date": "%d.%m.%Y",
    "time": "%H:%M:%S",
    "periods": [moment.localeData().meridiem(1, 0), moment.localeData().meridiem(13, 0)],
    "days": moment.weekdays(),
    "shortDays": moment.weekdaysShort(),
    "months": moment.months(),
    "shortMonths": moment.monthsShort()
  });
})();