/* D3Util */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {
  var module = {};
  module.DATE_FORMATS = {
    MONTH: "%B",
    YEAR: "%Y",
    MONTH_DAY: "%b %-d",
    MONTH_DAY_YEAR: "%b %-d %Y",
    TIME: "%-H:%M:%S",
    HOUR_ZERO_MINUTE: "%-H:00",
    HOUR_AM_PM: "%-I%p"
  };

  module.buildMultiTimeFormatter = function(formatArray) {
    return d3Locale.timeFormat.utc.multi(formatArray)
  };

  module.formatDateWithString = function(date, formatString) {
    var format = d3Locale.timeFormat.utc(formatString);
    return format(date);
  };

  module.formatToFixedDecimalPoints = function(precision) {
    return d3.format('.' + precision + 'f');
  };

  return module;
}));