/* Nvd3Util */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {
  var DEFAULT_COLORS = ['#01426A', '#D78825'];
  var ERROR_COLOR = '#C00';
  var ARBITRARILY_LARGE_KEY_LENGTH = 50;
  var MIN_PADDING_TICK_LABELS = 3;

  var brush = null;

  function buildChart(nvd3Chart, tooltipGenerator, options) {
    nvd3Chart.color(DEFAULT_COLORS.concat(d3.scale.category10().range()));
    nvd3Chart.tooltip.contentGenerator(tooltipGenerator);
    nvd3Chart.xAxis.axisLabel(options.xAxisLabel).showMaxMin(false);
    nvd3Chart.yAxis.axisLabel(options.yAxisLabel).showMaxMin(false);
    nvd3Chart.legend.maxKeyLength(ARBITRARILY_LARGE_KEY_LENGTH); //arbitrarily large number

    if ("xTickFormat" in options) {
      nvd3Chart.xAxis.tickFormat(options.xTickFormat);
    }
    if ("yTickFormat" in options) {
      nvd3Chart.yAxis.tickFormat(options.yTickFormat);
    }
    if ("forceY" in options) {
      nvd3Chart.forceY(options.forceY);
    }
    if ("forceX" in options) {
      nvd3Chart.forceX(options.forceX);
    }
    if ("xScale" in options) {
      nvd3Chart.xScale(options.xScale);
    }
    if ("yScale" in options) {
      nvd3Chart.yScale(options.yScale);
    }
    if ("legendUpdateState" in options) {
      nvd3Chart.legend.updateState(options.legendUpdateState);
    }
    if ("margin" in options) {
      nvd3Chart.margin(options.margin);
    }
    if ("noData" in options) {
      nvd3Chart.noData(options.noData);
    }

    nvd3Chart.x(function(d) {
      return d.x;
    });
    nvd3Chart.y(function(d) {
      return d.y;
    });

    return nvd3Chart;
  }

  function drawChart(chart, id, data, extraFeatures) {
    nv.addGraph(function() {
      clearAndRedrawChart(chart, id, data);
      nv.utils.windowResize(function() {
        chart.update();
        drawExtraFeatures(chart, id, extraFeatures);
        Nvd3Util.resizeErrorMessage(id);
      });
      return chart;
    }, function() {
      drawExtraFeatures(chart, id, extraFeatures);
      if (extraFeatures.timeScale) {
        clearOverlappingTickLabels(id);
      }
      if (extraFeatures.drawChartCallback) {
        extraFeatures.drawChartCallback();
      }
      if (extraFeatures.drawChartCallbackOnce) {
        extraFeatures.drawChartCallbackOnce();
        extraFeatures.drawChartCallbackOnce = null;
      }
    });
  }

  function clearOverlappingTickLabels(chart_id) {
    //nvd3 doesn't handle the time ticks spectacularly well and overwrites any tick properties set on the scale
    //Clear any labels that overlap - this only needs to happen on the first chart drawing.
    var dateTicks = d3.selectAll(chart_id + " .nv-x .tick")[0];
    for (var i = 1; i < dateTicks.length; i++) {
      var leftTick = dateTicks[i - 1];
      var rightTick = dateTicks[i];
      if (rightTick.getBoundingClientRect().left - leftTick.getBoundingClientRect().right < MIN_PADDING_TICK_LABELS) {
        d3.select(rightTick).remove();
        i++; //skip removed tick
      }
    }
  }

  function drawExtraFeatures(chart, id, extraFeatures) {
    if (extraFeatures.extraLinesData) {
      drawExtraLines(chart, id, extraFeatures.extraLinesData);
    }
    if (extraFeatures.selectorToggle && $(extraFeatures.selectorToggle).hasClass(Histogram.SELECTED_CLASS)) {
      brush ? drawBrush(chart, id, extraFeatures.brushOptions.drawBrushCallback) : drawBrushArea(chart, id, extraFeatures.brushOptions);
    }
    if (extraFeatures.areaData) {
      drawAreasOnChart(chart, id, extraFeatures.areaData);
    }
    if (extraFeatures.staticLegend) {
      d3.selectAll(id + ' .nv-series').style('cursor', 'default');
    }
  }

  function drawAreasOnChart(chart, id, areaData) {
    d3.selectAll(id + " .nv-wrap .nv-line .nmx_threshold_level").remove();
    var linesWrap = d3.select(id + " .nv-wrap .nv-line");
    var y = 0;
    areaData.forEach(function(area) {
      linesWrap.insert("rect", ":first-child")
          .attr({
            class: "nmx_threshold_level",
            x: 0,
            y: y,
            width: chart.xAxis.scale()(chart.xAxis.domain()[1]),
            height: chart.yAxis.scale()(area.height) - y,
            fill: area.colour,
            opacity: area.opacity
          });
      y = chart.yAxis.scale()(area.height);
    });
  }

  function clearAndRedrawChart(chart, id, data) {
    brush = null;
    d3.select(id)
        .datum(data)
        .call(chart);
  }

  function resizeBrush(id) {
    var gBrush = d3.select(id + " .brush");
    var chartArea = d3.select(id + ' .nv-barsWrap').node();
    if (gBrush[0] && brush && chartArea) {
      gBrush.call(brush.extent(getBrushExtent()))
          .selectAll('rect')
          .attr('height', chartArea.getBoundingClientRect().height);
    }
  }

  function clearBrush(id) {
    var gBrush = d3.select(id + " .brush");
    if (gBrush[0] && brush) {
      gBrush.call(brush.extent([0, 0]));
    }
  }

  function getBrushExtent() {
    return Histogram.getTimeRange();
  }

  function drawBrush(chart, chart_id, drawBrushCallback) {
    var range = getBrushExtent();
    if (isInnerWithinOuter(range, chart.xAxis.scale().domain())) {
      resizeBrush(chart_id);
    }
    else {
      clearBrush(chart_id);
    }
    drawBrushCallback();
  }

  function isInnerWithinOuter(innerInterval, outerInterval) {
    var MIN = 0;
    var MAX = 1;
    if ((innerInterval[MAX] < innerInterval[MIN]) || (innerInterval[MIN] == 0 || innerInterval[MAX] == 0)) {
      return false;
    }
    return (innerInterval[MIN] >= outerInterval[MIN] && innerInterval[MAX] <= outerInterval[MAX])
  }

  function drawBrushArea(chart, id, brushActions) {
    brush = d3.svg.brush()
        .x(chart.xAxis.scale())
        .on('brush', function() {
          if (!d3.event.target.empty()) {
            brushActions.onBrush(d3.event.target.extent());
          }
        })
        .on('brushend', function() {
          if (d3.event.target.empty()) {
            brushActions.onBrushEnd();
            drawBrush(chart, id, brushActions.drawBrushCallback);
          }
        });

    var chartAreaHeight = d3.select(id + ' .nv-barsWrap').node().getBoundingClientRect().height;

    //Based on the StackOverflow question: http://stackoverflow.com/questions/31698729/how-to-add-brush-in-nvd3-like-d3
    var brushParent = d3.select(id + ' g');
    var brushContainer = brushParent.append('g');
    brushContainer.attr("class", "brush")
        .call(brush)
        .selectAll('rect')
        .attr('height', chartAreaHeight);

    var resizeHandles = brushContainer.selectAll('.resize');
    resizeHandles.append("circle").attr("r", 5).attr("cy", chartAreaHeight / 2);

    drawBrush(chart, id, brushActions.drawBrushCallback);
  }

  function removeBrush(chart_id) {
    d3.select(chart_id + " .brush").remove();
  }

  function drawExtraLines(chart, id, data) {
    var lineWrapper = d3.select(id + " .nv-regressionLinesWrap");
    lineWrapper.selectAll('line').remove();

    data.forEach(function(lineData) {
      lineWrapper.append('line')
          .attr({
            x1: chart.xAxis.scale()(lineData.x1),
            x2: chart.xAxis.scale()(lineData.x2),
            y1: chart.yAxis.scale()(lineData.y1),
            y2: chart.yAxis.scale()(lineData.y2)
          })
          .style("stroke", "#000000")
          .style("stroke-dasharray", (lineData.linePattern));
    });

    //update legend to reflect lines drawn on graph
    //NOTE: this requires that a "hidden series" has been added to the plot
    // through the SeriesDataBuilder for each line drawn on the graph
    var legendItemArr = d3.selectAll(id + ' .nv-series')[0];
    legendItemArr.forEach(function(d) {
      var legendItem = d3.select(d);
      var legendItemText = legendItem.select('text');
      data.forEach(function(lineData) {
        if (legendItemText.text().match(new RegExp(lineData.text))) {
          legendItemText.style("font-weight", "bold");
          legendItem.select('circle').remove();
          legendItem.append('line')
              .attr({x1: -10, x2: 5, y1: 0, y2: 0}) //draw line where circle used to be
              .style("stroke", "#000000")
              .style("stroke-dasharray", (lineData.linePattern));
        }
      });
      legendItem.style("cursor", "default");
    });
  }

  var module = {};

  module.SeriesDataBuilder = function() {

    function padMissingIntervalXValues(data, xInterval, minValue, maxValue) {
      if (data.length == 0) {
        return;
      }

      var NO_VALUE_DATA = 0;

      var topLimit = maxValue ? maxValue : Number.POSITIVE_INFINITY;
      var bottomLimit = minValue ? minValue : Number.NEGATIVE_INFINITY;
      var min, max;
      var currentMin = topLimit;
      var currentMax = bottomLimit;

      var values = data[0].values;
      values.forEach(function(value, index) {
        var xValue = value.x;
        if (xValue < bottomLimit || xValue > topLimit) {
          values.splice(index, 1);
        } else {
          currentMin = Math.min(xValue, currentMin);
          currentMax = Math.max(xValue, currentMax);
        }
      });

      min = isNaN(minValue) ? currentMin : minValue;
      max = isNaN(maxValue) ? currentMax : maxValue;

      var missingIntervals = Math.ceil((max - min) / xInterval) - values.length;
      for (var i = 1; i <= missingIntervals; ++i) {
        values.push({
          x: min - xInterval,
          y: NO_VALUE_DATA
        });
      }
      values.push({
            x: min - xInterval,
            y: NO_VALUE_DATA
          },
          {
            x: max + xInterval,
            y: NO_VALUE_DATA
          });
    }

    this.data = [];

    this.addSeries = function(seriesData, label, color) {
      this.data.push({
        key: label,
        color: color,
        values: $.extend(true, [], seriesData)
      });
      return this;
    };

    this.addHiddenSeries = function(label, color) {
      this.data.push({
        key: label,
        color: color,
        values: []
      });
      return this;
    };

    this.padIntervalXValues = function(xInterval, minValue, maxValue) {
      padMissingIntervalXValues(this.data, xInterval, minValue, maxValue);
      return this;
    };

    this.convertXValuesToFakeUtcDate = function() {
      this.data.forEach(function(dataSeries) {
        var values = dataSeries.values;
        values.forEach(function(value) {
          value.x = Util.convertToTimezoneAndFakeUTC(value.x);
        });
      });
      return this;
    };

    this.getMaxY = function() {
      var maxY = -Infinity;
      this.data.forEach(function(dataSeries) {
        var values = dataSeries.values;
        values.forEach(function(value) {
          maxY = Math.max(maxY, value.y);
        });
      });
      return maxY;
    };

    this.build = function() {
      return this.data;
    };

    return this;
  };

  module.extraLinesDataBuilder = function() {
    this.data = [];

    this.addLine = function(x1, y1, x2, y2, textMatch, linePattern) {
      this.data.push({
        x1: x1,
        y1: y1,
        x2: x2,
        y2: y2,
        text: textMatch,
        linePattern: linePattern
      })
    };

    this.build = function() {
      return this.data;
    };
    return this;
  };
  
  module.filterToPowersOf10 = function(number) {
    if (Util.isPowerOf10(number)) {
      return number;
    }
    else {
      return "";
    }
  };

  module.ChartBuilder = (function() {
    var builder = {};
    var options = {};
    var extraFeatures = {};

    var Y_AXIS_BUFFER = 0.2;

    function ChartWrapper(nvd3chart, extraFeatures) {
      this.nvd3Chart = nvd3chart;
      this.extraFeatures = extraFeatures;
      this.id = "";
    }

    ChartWrapper.prototype.drawChart = function(id, data) {
      this.id = id;
      drawChart(this.nvd3Chart, id, data, this.extraFeatures);
    };

    ChartWrapper.prototype.setXAxis = function(minX, minY) {
      this.nvd3Chart.forceX([minX, minY]);
      return this;
    };

    ChartWrapper.prototype.padYAxis = function(minY, maxY) {
      this.nvd3Chart.forceY([minY * (1 - Y_AXIS_BUFFER), maxY * (1 + Y_AXIS_BUFFER)]);
      return this;
    };

    ChartWrapper.prototype.setDrawChartCallbackOnce = function(callback) {
      $.extend(this.extraFeatures, {drawChartCallbackOnce: callback});
      return this;
    };

    ChartWrapper.prototype.addAreas = function(data) {
      $.extend(this.extraFeatures, {areaData: data});
      return this;
    };

    ChartWrapper.prototype.updateChart = function() {
      if (this.nvd3Chart.update) {
        this.nvd3Chart.update();
        drawExtraFeatures(this.nvd3Chart, this.id, this.extraFeatures);
      }
    };

    ChartWrapper.prototype.drawBrush = function() {
      drawBrushArea(this.nvd3Chart, this.id, this.extraFeatures.brushOptions);
    };

    ChartWrapper.prototype.moveBrush = function() {
      drawBrush(this.nvd3Chart, this.id, this.extraFeatures.brushOptions.drawBrushCallback);
    };

    ChartWrapper.prototype.removeBrush = function() {
      removeBrush(this.id);
    };

    ChartWrapper.prototype.isRangeInXAxis = function(range) {
      return isInnerWithinOuter(range, this.nvd3Chart.xAxis.scale().domain());
    };

    builder.setOptions = function(opt) {
      options = opt;
      return builder;
    };

    builder.setLogScale = function(maxY) {
      var logOptions = {
        forceY: [Nvd3Util.LOG_CHART_MIN_Y, Nvd3Util.calculateLogChartMaxY(maxY)],
        yScale: d3.scale.log(),
        yTickFormat: Nvd3Util.filterToPowersOf10
      };
      $.extend(options, logOptions);
      return builder;
    };

    builder.setTimeScale = function(xTickFormatter) {
      var scale = d3.time.scale.utc();
      var timeOptions = {
        xScale: scale,
        xTickFormat: function(d) {
          return xTickFormatter(d, scale.domain())
        }
      };
      $.extend(options, timeOptions);
      $.extend(extraFeatures, {timeScale: true});
      return builder;
    };

    builder.addBrushSelector = function(toggle, options) {
      $.extend(extraFeatures, {selectorToggle: toggle, brushOptions: options});
      return builder;
    };

    builder.addExtraLines = function(data) {
      $.extend(options, {legendUpdateState: false});
      $.extend(extraFeatures, {extraLinesData: data});
      return builder;
    };

    builder.setStaticLegend = function() {
      $.extend(options, {legendUpdateState: false});
      $.extend(extraFeatures, {staticLegend: true});
      return builder;
    };

    builder.setDrawChartCallback = function(callback) {
      $.extend(extraFeatures, {drawChartCallback: callback});
      return this;
    };

    builder.buildBarChart = function(tooltipGenerator) {
      var nvd3Chart = buildChart(nv.models.historicalBarChart(), Nvd3Util.barChartTooltipGenerator(tooltipGenerator), options);
      return new ChartWrapper(nvd3Chart, extraFeatures);
    };

    builder.buildScatterChart = function(tooltipGenerator) {
      var nvd3Chart = buildChart(nv.models.scatterChart(), Nvd3Util.scatterChartTooltipGenerator(tooltipGenerator), options);
      return new ChartWrapper(nvd3Chart, extraFeatures);
    };

    builder.buildMultiBarChart = function(tooltipGenerator) {
      var nvd3Chart = buildChart(nv.models.multiBarChart(), Nvd3Util.barChartTooltipGenerator(tooltipGenerator), options);
      return new ChartWrapper(nvd3Chart, extraFeatures);
    };

    builder.buildLineChart = function(tooltipGenerator) {
      var nvd3Chart = buildChart(nv.models.lineChart(), Nvd3Util.scatterChartTooltipGenerator(tooltipGenerator), options);
      return new ChartWrapper(nvd3Chart, extraFeatures);
    };

    return function() {
      options = {};
      extraFeatures = {};
      return builder;
    };

  })();

  module.LOG_CHART_MIN_Y = 0.8;

  module.calculateLogChartMaxY = function(highestYValue) {
    return Math.pow(10, Math.ceil(Math.log(highestYValue) / Math.LN10));
  };

  module.calculateLogChartX = function(y, yIntercept, slope) {
    return ((Math.log(y) / Math.LN10) - yIntercept) / slope;
  };

  module.barChartTooltipGenerator = function(tooltipGenerator) {
    return function(obj) {
      return tooltipGenerator(obj.data.x, obj.data.y);
    };
  };

  module.scatterChartTooltipGenerator = function(tooltipGenerator) {
    return function(obj) {
      return tooltipGenerator(obj.point.x, obj.point.y, obj.seriesIndex);
    };
  };

  module.textTooltipGenerator = function(text) {
    return '<div class="nvd3-tooltip">' + text + '</div>';
  };

  module.displayNoDataErrorMessage = function(chartID, message) {
    d3.select(chartID).select('.nv-noData').attr("fill", ERROR_COLOR).text(message);
  };

  module.displayErrorMessage = function(chartID, message) {
    var legendWrap = d3.select(chartID).select('.nv-legend').select('g');
    if (legendWrap[0][0]) {
      d3.selectAll(chartID + " .nv-legend .nmx_error_message").remove();
      var leftShift = d3.transform(legendWrap.attr("transform")).translate[0] * -1;
      legendWrap
          .append("text")
          .attr({
            fill: ERROR_COLOR,
            transform: "translate(" + leftShift + ",10)",
            class: "nmx_error_message"
          })
          .text(message);
    }
  };

  module.clearErrorMessage = function(chartID) {
    d3.selectAll(chartID + " .nv-legend .nmx_error_message").remove();
  };

  module.resizeErrorMessage = function(chartID) {
    var errorMessage = d3.select(chartID + " .nv-legend .nmx_error_message");
    if (errorMessage[0][0]) {
      var legendWrap = d3.select(chartID).select('.nv-legend').select('g');
      var leftShift = d3.transform(legendWrap.attr("transform")).translate[0] * -1;
      errorMessage
        .transition()
        .attr({
          transform: "translate(" + leftShift + ",10)"
        });
    }
  };

  return module;
}));