/* Popup -- used by FMD plot page date popup */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {

  var module = {};

  var timer = null;
  var button = null;
  var data = null;
  var ignoreClose = null;

  module.start = function (aButton, aData) {
    if (button != null) {
      module.close();
    }
    else {
      button = $(aButton);
      button.addClass('popped');
      var popup = $(button).select('div');
      data = aData;
    }
  };

  module.leave = function (element) {
    timer = setTimeout(module.close, 500);
  };

  module.enter = function (element) {
    if (timer != null) {
      clearTimeout(timer);
      timer = null;
    }
  };

  module.ignoreClose = function (ignore) {
    ignoreClose = ignore;
  };

  module.close = function () {
    if (ignoreClose != null) {
      ignoreClose = null;
      return;
    }
    if (button != null) {
      button.removeClass('popped');
      button = null;
    }
    if (timer != null) {
      clearTimeout(timer);
      timer = null;
    }
  };

  module.data = function () {
    return data;
  };

  return module;
}));