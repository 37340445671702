/* DatetimeRange -- calculates ranges for date range popup */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {

  var module = {};

  var START_OF_DAY = "00:00:00";
  var END_OF_DAY = "23:59:59";
  var currentYear = moment.utc().year();
  // moment months are zero-indexed
  var currentMonth = moment.utc().month() + 1;

  module.set = function (startDate, endDate) {
    justSet(startDate, endDate);
    Popup.close();
  };

  module.year = function (year) {
    currentYear = year;
    justSet(year + "-01-01 " + START_OF_DAY, year + "-12-31 " + END_OF_DAY);
    Popup.ignoreClose(true);
  };

  module.month = function (month) {
    currentMonth = month;
    justSet(currentYear + "-" + Util.pad(month) + "-01 " + START_OF_DAY, currentYear + "-" +
      Util.pad(month) + "-" + lastDayOfMonth(currentYear, month) + " " + END_OF_DAY);
    Popup.ignoreClose(true);
  };

  module.day = function (day) {
    var date = currentYear + "-" + Util.pad(currentMonth) + "-" + Util.pad(day);
    justSet(date + " " + START_OF_DAY, date + " " + END_OF_DAY);
    Popup.ignoreClose(true);
  };

  function lastDayOfMonth(year, month) {
    if (month === 12) {
      month = 0;
      year += 1;
    }
    var date = new Date(year, month, 1);
    var ms = date.getTime();
    ms -= 1000 * 60 * 60 * 24;
    var endDate = new Date(ms);
    return endDate.getDate();
  }

  function justSet(startDate, endDate) {
    $('#' + Popup.data().startField).val(startDate).trigger("change");
    $('#' + Popup.data().endField).val(endDate).trigger("change");
  }

  return module;
}));