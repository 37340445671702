/* Form */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define([], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory();
  } else {
    // Browser globals (root is window)
    root.returnExports = factory();
  }
}(typeof self !== 'undefined' ? self : this, function () {
  var module = {};

  module.insertFields = function (link, method, content) {
    var newId = new Date().getTime();
    var regexp = new RegExp("new_" + method, "g");
    var removableRows = $(".removable_rows");
    if (removableRows.length) {
      removableRows.append(content.replace(regexp, newId));
      $(".removable_rows_table").show();
    }
    else {
      $(link).parent().before(content.replace(regexp, newId));
    }
    module.activateDateTimePickers();
  };

  module.showHeader = function () {
    if ($(".removable_rows").children(':visible').length === 0) {
      $(".removable_rows_table").hide();
    }
    else {
      $(".removable_rows_table").show();
    }
  };
  module.removeFields = function (link) {
    if (confirm("Are you sure?")) {
      if ($(".removable_row").length) {
        $(link).closest(".removable_row").hide();
        $(link).prev("input[type=hidden]").val("1");
        module.showHeader();
      }
      else {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".inputs").hide();
      }
    }
  };

  module.activateDateTimePickers = function () {
    var baseOptions = {
      dateFormat: 'yy-mm-dd',
      showSecond: true,
      timezone: 0
    };

    var noMillisOptions = $.extend({}, baseOptions, {
      showMillisec: false,
      timeFormat: 'HH:mm:ss'
    });

    var noMillisEndOptions = $.extend({}, noMillisOptions, {
      hour: 23,
      minute: 59,
      second: 59
    });

    var withMillisOptions = $.extend({}, baseOptions, {
      showMillisec: true,
      millisecText: "Millis",
      timeFormat: 'HH:mm:ss.l',
      showOn: "button",
      buttonImage: require('images/calendar.png'),
      buttonImageOnly: true,
      buttonText: "Show datepicker"
    });

    var i18nLocalesToDatepickerLocales = {
      "en": "",
      "pt-br": "pt-BR"
    };
    var locale = i18nLocalesToDatepickerLocales[I18n.locale] || I18n.locale;
    var localizedOptions = $.extend({}, $.datepicker.regional[locale], $.timepicker.regional[locale], noMillisOptions, {
      timezone: Util.getConfiguredTimezoneOffset() * -1,
      afterInject: function () {
        $("button.ui-datepicker-forever").html(I18n.t("globals.timepicker.forever"));
      }
    });

    $.datepicker.setDefaults($.extend({}, $.datepicker.regional[""]));
    $.timepicker.setDefaults($.timepicker.regional[""]);

    // Used on event criteria form
    $("input.start_datetime_picker_no_millis").datetimepicker(noMillisOptions);
    $("input.end_datetime_picker_no_millis").datetimepicker(noMillisEndOptions);

    // Used on b-value graphs form
    $("input.start_datetime_picker_no_millis_timezone").datetimepicker(localizedOptions);
    $("input.end_datetime_picker_no_millis_timezone").datetimepicker(localizedOptions);

    // Used everywhere else
    $("input.datetime_picker").datetimepicker(withMillisOptions);
  };


  $(document).ready(function () {
    Form.activateDateTimePickers();
  });

  return module;
}));